import React from 'react'
import Layout from '../components/Layout'
import { withIntl } from '../i18n'

const NotFoundPage = () => (
  <Layout>
    <section id="404" className="section is-medium">
      <div className="container">
        <h1>Page not found</h1>
        <p>
          The page you searched for does not exist. Send us an email below about
          what you are searching for.
        </p>
      </div>
    </section>
    <section className="section  is-medium has-background-primary contact-form">
      <div className="container">
        <form
          name="404-side-ikke-funnet"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          style={{
            padding: '40px',
            background: '#89A5B8',
          }}
        >
          <input type="hidden" name="bot-field" />
          <h3 className="title has-text-centered">
            Is there any bugs on our page? Let us know
          </h3>
          <div className="control">
            <input
              className="input"
              type="text"
              name="tittel"
              placeholder="Title of problem"
            />
          </div>
          <div className="control">
            <textarea
              className="textarea"
              rows="4"
              name="beskrivelse"
              placeholder="Description"
            />
          </div>
          <div className="control">
            <input className="button" type="submit" value="Send" />
          </div>
        </form>
      </div>
    </section>
  </Layout>
)

export default withIntl(NotFoundPage)
